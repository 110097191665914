import styles from "./styles.module.scss";
import { workflows } from "../../data/workflows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useMemo, useState } from "react";
import ReactLoading from "react-loading";
import { API_URLS, fetchApi } from "../../api";

export const Workflow = ({ workflowIdSelected }) => {
  const workflow = useMemo(
    () => workflows[workflowIdSelected],
    [workflowIdSelected]
  );

  const [isExecutingWorkflow, setIsExecutingWorkflow] = useState(false);
  const [inputs, setInputs] = useState(workflow.inputs.map(() => ""));

  const executeWorkflow = async () => {
    setIsExecutingWorkflow(true);

    if (workflow.id === "create-hubspot-deal") {
      await fetchApi(API_URLS.CREATE_DEAL_FOR_CONTACT, {
        contact_url: inputs[0],
      });
    }

    setIsExecutingWorkflow(false);
  };

  return (
    <div className={styles.Workflow}>
      <div className={styles.companyTitleContainer}>
        <img
          src={require("../../assets/realFrequencyLogo.png")}
          className={styles.companyLogo}
        />
        <p className={styles.companyName}>Real Frequency</p>
      </div>
      <div className={styles.workflowBasicDetails}>
        <div className={styles.nameTimeContainer}>
          <h2 className={styles.workflowName}>{workflow.name}</h2>
          <p className={styles.workflowTimeText}>{`Takes ~${
            workflow.duration
          } sec${workflow.duration > 1 ? "s" : ""} to run`}</p>
        </div>
        {isExecutingWorkflow ? (
          <ReactLoading color={"#483C9F"} type="spin" height={40} width={40} />
        ) : (
          <FontAwesomeIcon
            className={styles.playCircle}
            icon={faCirclePlay}
            onClick={() => executeWorkflow()}
          />
        )}
      </div>
      <p className={styles.workflowDescription}>{workflow.description}</p>
      <div className={styles.workflowDetails}>
        <div className={styles.steps}>
          {workflow.steps.map((step, index) => (
            <div className={styles.step} key={index}>
              <div className={styles.stepTextContainer}>
                <p className={styles.stepText}>{step}</p>
              </div>
              {index !== workflow.steps.length - 1 && (
                <img
                  src={require("../../assets/arrowDown.png")}
                  className={styles.arrowDown}
                />
              )}
            </div>
          ))}
        </div>
        <div className={styles.requiredInputs}>
          <p className={styles.requiredInputsTitle}>Required Inputs</p>
          {workflow.inputs.map((input, index) => (
            <div className={styles.input} key={index}>
              <p className={styles.inputName}>{`${input}`}</p>
              <textarea
                className={styles.inputValue}
                placeholder="Type here..."
                value={inputs[index]}
                onChange={(value) => {
                  const newInputs = [...inputs];
                  newInputs[index] = value.target.value;
                  setInputs(newInputs);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
