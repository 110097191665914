import styles from "./styles.module.scss";
import classNames from "classnames";
import { workflows } from "../../data/workflows";

export const SideBar = ({ workflowIdSelected, onWorkflowIdSelected }) => {
  return (
    <div className={styles.SideBar}>
      <div className={styles.logoNameRow}>
        <img
          src={require("../../assets/blackCopyCat.png")}
          className={styles.logo}
          alt="logo"
        />
        <p className={styles.copycatName}>Copy Cat</p>
      </div>
      <div className={styles.sideBarContainer}>
        <h2 className={styles.workflowsTitle}>Workflows</h2>
        <div className={styles.buttonsContainer}>
          {Object.values(workflows).map((workflow) => (
            <button
              key={workflow.id}
              className={classNames(styles.workflowButton, {
                [styles.selected]: workflowIdSelected === workflow.id,
              })}
              onClick={() => onWorkflowIdSelected(workflow.id)}
            >
              {workflow.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
