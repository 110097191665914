
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBNUymblHDPL_i3yW3H7Knh3j50aq5eiPk",
  authDomain: "copycatdemo-ce2e6.firebaseapp.com",
  projectId: "copycatdemo-ce2e6",
  storageBucket: "copycatdemo-ce2e6.firebasestorage.app",
  messagingSenderId: "710062818166",
  appId: "1:710062818166:web:beb7b07d4af74591aff057"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };