export const workflows = {
  "create-hubspot-deal": {
    id: "create-hubspot-deal",
    name: "Create Hubspot Deal for New Customer",
    description: "This workflow will create a new HubSpot deal for a given incoming contact form submission.",
    duration: 1,
    steps: [
      "Create Deal for Contact in HubSpot",
      "Verify Deal Moved to Needs Consultation Stage in HubSpot"
    ],
    inputs: [
      "Contact URL",
    ]
  },
  "close-hubspot-deal": {
    id: "close-hubspot-deal",
    name: "Close Hubspot Deal",
    description: "This workflow will build a quote for a given deal & send it to the customer via email.",
    duration: 2,
    steps: [
      "Build Quote for Deal in HubSpot",
      "Sned Quote to Customer in HubSpot via Email"
    ],
    inputs: [
      "Deal URL",
      "Extra Messaging to Send to Customer in Email"
    ]
  },
  "contact-customer-after-payment": {
    id: "contact-customer-after-payment",
    name: "Contact Customer After Payment",
    description: "This workflow will create a contact in HubSpot for the child, send a welcome email, and send a welcome text via HeyMarket.",
    duration: 3,
    steps: [
      "Create Contact for Child in HubSpot",
      "Send Welcome Email to Parent in HubSpot",
      "Send Welcome Text to Child in HeyMarket"
    ],
    inputs: [
      "Child Name",
      "Child Email",
      "Child Phone Number",
    ]
  },
};
