import { useState } from "react";
import { SideBar } from "./components/SideBar/SideBar";
import { Workflow } from "./components/Workflow/Workflow";
import styles from "./styles.module.scss";
import { getItemFromLocalStorage, putItemIntoLocalStorage } from "./utils/localStorage";

const App = () => {
  const [workflowIdSelected, setWorkflowIdSelected] = useState(
    "create-hubspot-deal"
  );
  const [isAuthenticated, setIsAuthenticated] = useState(
    getItemFromLocalStorage("isAuthenticated") === "true"
  );
  const [signInCode, setSignInCode] = useState("");
  const [incorrectSignInCode, setIncorrectSignInCode] = useState(false);

  const attemptToSignIn = () => {
    const correctSignInCode = "realfrequency123";
    const enteredSignInCode = signInCode.toLowerCase();

    if (enteredSignInCode === correctSignInCode) {
      putItemIntoLocalStorage("isAuthenticated", "true");
      setIsAuthenticated(true);
    } else {
      setIncorrectSignInCode(true);
    }
  }

  if (!isAuthenticated) {
    return (
      <div className={styles.AppNotSignedInContainer}>
        <img
          src={require("./assets/transparentWhiteLogo.png")}
          className={styles.notSignedInLogo}
        />
        <p className={styles.notSignedInInstructions}>
          Enter your CopyCat access code
        </p>
        <input
          className={styles.notSignedInInput}
          value={signInCode}
          onChange={(e) => setSignInCode(e.target.value)}
          placeholder="Enter code..."
        />
        <button
          className={styles.notSignedInButton}
          onClick={attemptToSignIn}
        >
          Sign in
        </button>
        <p className={styles.notSignedInError}>
          {incorrectSignInCode ? "Incorrect code. Please try again." : ""}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.App}>
      <SideBar
        workflowIdSelected={workflowIdSelected}
        onWorkflowIdSelected={setWorkflowIdSelected}
      />
      <Workflow workflowIdSelected={workflowIdSelected} />
    </div>
  );
};

export default App;
