import { signInAnonymously } from "firebase/auth";
import { auth } from "./firebase";

const isDev = process.env.NODE_ENV === "development";

export const API_URLS = {
  CREATE_DEAL_FOR_CONTACT: isDev
    ? "http://127.0.0.1:5001/copycatdemo-ce2e6/us-central1/create_deal_for_contact"
    : "https://create-deal-for-contact-ks6lzmhlua-uc.a.run.app",
};

export const fetchApi = async (apiUrl, body) => {
  await signInAnonymously(auth);
  const idToken = await auth.currentUser.getIdToken();

  const theFetch = await fetch(apiUrl, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(body),
    method: "POST",
  });
  const theData = await theFetch.text();

  return theData;
};
