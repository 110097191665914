export const isLocalStorageAvailable = () => {
  try {
    const storageTestKey = "__platter_storage_test_key__";
    const storageTestValue = "__platter_storage_test_value__";

    localStorage.setItem(storageTestKey, storageTestValue);
    localStorage.removeItem(storageTestKey);

    return true;
  } catch {
    return false;
  }
};

export const putItemIntoLocalStorage = (key, value) => {
  if (!isLocalStorageAvailable()) {
    return;
  }

  localStorage.setItem(key, value);
};

export const getItemFromLocalStorage = (key) => {
  if (!isLocalStorageAvailable()) {
    return null;
  }

  const value = localStorage.getItem(key);

  return value;
};

export const removeItemFromLocalStorage = (key) => {
  if (!isLocalStorageAvailable()) {
    return;
  }

  localStorage.removeItem(key);
};
